import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Breadcrumbs from '../../components/toolbar/view'
import Loading from '../../components/general/Loading'
import ModelService from '../../core/services/model-service/model-service'
import { evalJSONContext, getSpecificationByFields } from '../../util/util'
import useHandleField from '../../components/form/model/main'
import ButtonLoading from '../../components/general/ButtonLoading'
import alertify from 'alertifyjs'
import TabView from '../../components/form/view/tab-view'
import TabsView from '../../components/form/view/tabs-view'
import useFormStore from '../../store/form'
import { useQuery } from '@tanstack/react-query'
import ViewServices from '../../core/services/view-service/view-service'
import useHeaderStore from '../../store/header'
import { VIEW_TYPE } from '../../util/constant/view-type'
import { useTranslation } from 'react-i18next'


const FormView = () => {
  const [searchParams] = useSearchParams()
  const resModel = searchParams.get("model")
  const id = parseInt(searchParams.get("id"))
  const vid = searchParams.get("vid")
  const { lang } = useHeaderStore()
  const [onchangeData, setOnchangeData] = useState()
  const [objVal, setObjVal] = useState({})
  const [fieldChange, setFieldChange] = useState([])
  const [loading, setLoading] = useState(false)
  const { setViewDataStore } = useFormStore()
  const navigate = useNavigate()
  const { t } = useTranslation()
  // const [isLoadingFields, setLoadingFields] = useState(false)

  const methods = useForm({
    mode: "onBlur",
    values: ModelService.toDataJS(onchangeData),
  });

  const formValues = methods.watch()
  const { dirtyFields } = methods.formState

  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}-${lang}`, vid, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    refetchOnWindowFocus: false
  })
  const actionData = actionReponse?.[0]
  const nameAction = actionData?.name
  const context = actionData?.context ? evalJSONContext(actionData?.context) : {}

  const { data: viewResponse, loading: isLoadingView } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, actionData?.id, lang],
    queryFn: () => ViewServices.getFieldView({ resModel: actionData?.res_model, views: actionData?.views, lang: lang }),
    enabled: !!actionData,
    refetchOnWindowFocus: false
  })
  const viewData = viewResponse
  const toolbar = viewResponse?.views?.tree?.toolbar
  const fields = viewResponse?.views?.form?.fields
  const oe_title = viewResponse?.views?.form?.oe_title || []
  const specification = viewResponse && getSpecificationByFields(
    [...oe_title, ...viewResponse?.views?.form?.fields, ...viewResponse?.views?.form?.tabs],
    {},
    viewData,
    resModel
  )

  const fetchData = async () => {
    try {
      sessionStorage.setItem('actionData', JSON.stringify(actionReponse[0]))
      sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      setViewDataStore(viewResponse)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchData()
  }, [viewResponse])

  const filterFieldDirty = (id, viewData, formValues, dirtyFields, resModel) => {
    const data = id ? { ...dirtyFields } : { ...formValues }
    for (const key in data) {
      if (viewData?.models?.[resModel]?.[key]?.type === 'one2many') {
        const lineData = [];
        (formValues[key] ??= []).forEach((itemData, index) => {
          if (!itemData?.id) {
            lineData.push([0, `virtual_${index}`, itemData])
          } else {
            dirtyFields[key].forEach((itemDirty) => {
              lineData.push([
                1,
                itemData?.id,
                filterFieldDirty(itemData?.id, viewData, itemData, itemDirty, viewData?.models?.[resModel]?.[key]?.relation)
              ])
            })
          }
        })
        data[key] = lineData
      } else if (id) {
        data[key] = formValues?.[key]
      }
    }
    return data
  }

  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeReponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: specification,
        context: context,
        object: objVal,
        fieldChange: fieldChange
      })
      return onchangeReponse?.value
    } catch (err) {
      console.log(err);
    }
  }

  const fetchDetailData = async () => {
    try {
      const dataResponse = await ModelService.getDetailData({
        model: resModel,
        ids: id ? [id] : [],
        specification: specification,
        context: context
      })
      if (dataResponse.length > 0) {
        return dataResponse[0]
      }

      return {}
    } catch (err) {
      console.log(err);
    }
  }

  const { isLoading: isLoadingFields } = useQuery({
    queryKey: [`formData-${resModel}`, id],
    queryFn: async () => {
      if (!id) {
        const onchangeResponse = await fetchOnchange({
          ids: id ? [id] : [],
          model: resModel,
          specification: specification,
          context: context,
          object: objVal,
          fieldChange: fieldChange
        })
        setOnchangeData(onchangeResponse)
      } else {
        const detailData = await fetchDetailData()
        setOnchangeData(detailData)
      }
    },
    enabled: !!specification,
    refetchOnWindowFocus: false
  })

  const { fieldList } = useHandleField({ fields: fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData })

  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const data = ModelService.parseORMOdoo(filterFieldDirty(id, viewData, formValues, dirtyFields, resModel))
      const response = await ModelService.saveForm({ ids: id ? [id] : [], resModel: resModel, data: data, specification: specification, context: context })
      if (response && response.length > 0) {
        searchParams.set("id", response?.[0]?.id)
        const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
        navigate(_url)
      }
      setLoading(false)
      alertify.success(!id ? `Create Successfully` : 'Update Successfully');
    } catch (error) {
      console.log(error);
      setLoading(false);
      alertify.error(!id ? `Create Failed` : 'Update Failed');
    }
  };

  return (
    <div div className='page-wrapper' >
      {
        (!isLoadingFields) ?
          <FormProvider {...methods}>
            <Breadcrumbs
              title={nameAction}
              subTitle={!id ? t("new") : onchangeData?.name || onchangeData?.id}
              viewData={viewData}
              actionData={actionData}
              dataToolbar={toolbar}
              vid={vid}
              model={resModel}
              isInForm={true}
            />
            <form className='relative' onSubmit={methods.handleSubmit(handleFormSubmit)}
            >
              <div className='flex flex-col gap-4 w-full'>
                <TabView name={t("infor_form")} active={true} fieldList={fieldList} />
                <TabsView viewData={viewData} onchangeData={onchangeData} resModel={resModel} context={actionData?.context} />
                <div className="flex flex-row-reverse gap-2 w-full">
                  {id ? <>
                    <button
                      type='button'
                      className={`button-primary ${loading ? "opacity-50" : "hover:opacity-85"}`}
                      onClick={() => {
                        searchParams.delete('id')
                        const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
                        navigate(_url)
                      }}
                    >
                      {t("create_button")}
                    </button>
                    <ButtonLoading
                      loading={loading}
                      className={`button-primary ${loading ? "opacity-50" : "hover:opacity-85"}`}
                      content={t("update_button")}
                    />
                  </> : <>
                    <ButtonLoading
                      loading={loading}
                      className={`button-primary ${loading ? "opacity-50" : "hover:opacity-85"}`}
                      content={t("save")}
                    />
                  </>
                  }
                </div>
              </div>
            </form>
          </FormProvider> :
          <Loading />
      }
    </div >
  )
}

export default FormView
