import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import useAsync from '../../hooks/useAsync'
import ViewServices from '../../core/services/view-service/view-service'

const Many2ManyTag = ({ relation, name, methods, readonly, placeholder, required, invisible, defaultValue, isForm }) => {
  const { data: dataOfSelection } = useAsync(
    () => ViewServices.getSelectionItem({ model: relation }),
    `data_${relation}`
  )
  console.log("🚀 ~ Many2ManyTag ~ dataOfSelection:", dataOfSelection)

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.name,
    })) || []

  if (!isForm) {
    console.log("hdshhd");

    <span>test</span>
  } else {
    return (
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => {
          console.log("🚀 ~ Many2ManyTag ~ field:", Object.values(field.value))
          return <Select
            options={options}
            isMulti
            value={options.filter((option) => field.value?.some(item => item.id === option.value))}
            onChange={(selectedOptions) => {
              const newValue = selectedOptions.map((option) => option.value)
              field.onChange(newValue)
            }}
            className='col-span-2 mb-2'
            // isDisabled={readonly}
            placeholder={placeholder}
            required={!invisible && required}
          />
        }}
      />
    )
  }
}

export default Many2ManyTag
