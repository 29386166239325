import React from 'react'
import { Filter, Layers } from 'react-feather'
import { convertDomainStringToArray } from '../../../util/util'
const PopupFilter = ({ filterBy, groupBy, handleAddTag }) => {
  return (
    <div className='popup-filter absolute right-0 top-[calc(100%_+_3px)] z-20 w-auto min-w-full overflow-x-auto rounded-[0.25rem] border bg-white'>
      <div className='grid grid-cols-2 py-3'>
        <div className='max-w-[220px] border-r border-[#dee2e6] px-3'>
          <div className='flex w-fit items-center justify-start gap-2 px-3 py-1'>
            <Filter size={16} className='filter-icon h-4 w-4' />
            <span className='font-bold text-[#212529]'>Filter</span>
          </div>
          {filterBy?.map((item, index) => (
            <button
              onClick={() => {
                handleAddTag({
                  title: <Filter size={16} className='h-4 w-4' />,
                  name: item?.name,
                  value: item?.string,
                  icon: <Filter />,
                  domain: convertDomainStringToArray(item?.domain),
                })
              }}
              key={'filter-' + index + 1}
              className='w-full bg-white px-3 py-1 text-left hover:!bg-[rgba(0,0,0,0.08)]'
            >
              {item?.string ?? item?.help}
            </button>
          ))}
        </div>
        <div className='max-w-[220px] px-3'>
          <div className='flex w-fit items-center justify-start gap-2 px-3 py-1'>
            <Layers size={16} className='layer-icon h-4 w-4' />
            <span className='font-bold text-[#212529]'>Group By</span>
          </div>
          {groupBy?.map(
            (item, index) =>
              item.string && (
                <button
                  onClick={() =>
                    handleAddTag({
                      title: <Layers size={16} className='h-4 w-4' />,
                      name: item?.name,
                      value: item?.string,
                      icon: <Layers />,
                    })
                  }
                  key={'filter-' + index + 1}
                  className='w-full bg-white px-3 py-1 text-left hover:!bg-[rgba(0,0,0,0.08)]'
                >
                  {item?.string}
                </button>
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default PopupFilter
