import { useMemo } from "react";
import FieldView from "../../field/field-view";
import GroupView from "../view/group-view";

const useHandleField = ({ fields, viewData, resModel, onchangeData = {} }) => {
    const fieldList = useMemo(() => {
        return (fields ??= [])?.map((field, index) => {
            if (field?.type_co === "field") {
                const fieldDataRoot = viewData?.models?.[resModel]?.[field?.name] || {};
                const mergedField = { ...fieldDataRoot, onchangeData: onchangeData, ...field };

                return (
                    <FieldView {...mergedField} isForm={true} />
                );
            }
            else if (field?.type_co === "group") {
                const mergeProps = { viewData: viewData, resModel: resModel, onchangeData: onchangeData, ...field }
                return (<GroupView {...mergeProps} />)
            }

        });
    }, [fields, viewData, resModel, onchangeData]);

    return {
        fieldList,
    }
};

export default useHandleField