import { useSearchParams } from 'react-router-dom'
import Loading from '../../components/general/Loading'
import React, { useEffect } from 'react'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONContext, getSpecificationByFields, parseInputString } from '../../util/util'
import ModelService from '../../core/services/model-service/model-service'
import { useQuery, useIsFetching } from '@tanstack/react-query'
import useTable from '../../components/table/model/table'
import TableView from '../../components/table/view/table-view'
import useTableStore from '../../store/table'
import Breadcrumbs from '../../components/toolbar/view'
import useFormStore from '../../store/form'
import Pagination from '../../components/general/Pagination'
import useHeaderStore from '../../store/header'

const TreeView = () => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get('vid')
  const resModel = searchParams.get("model")
  const { fields, setFields, domainTable, setDomainTable, page, setPage } = useTableStore()
  const { setViewDataStore, viewDataStore } = useFormStore()
  const isFetching = useIsFetching()
  const { lang } = useHeaderStore()

  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}`, vid, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    refetchOnWindowFocus: false
  })
  const actionData = actionReponse?.[0]

  const { data: viewResponse } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, lang, actionData?.id],
    queryFn: () => ViewServices.getFieldView({ resModel: actionData?.res_model, views: actionData?.views, lang: lang }),
    enabled: !!actionData,
    refetchOnWindowFocus: false
  })
  const viewData = viewResponse
  const domain = actionReponse?.[0]?.domain ? [parseInputString(actionReponse?.[0]?.domain)] : []
  const specification = viewResponse && getSpecificationByFields(viewResponse?.views?.tree?.fields, {}, viewData, resModel)

  const fetchData = async () => {
    try {
      setDomainTable(domain)
      sessionStorage.setItem('actionData', JSON.stringify(actionReponse?.[0]))
      sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      setViewDataStore(viewResponse)

      if (!fields?.[`${vid}_${resModel}`]) {
        setFields({ ...fields, [`${vid}_${resModel}`]: viewResponse?.views?.tree?.fields })
      }
      setPage(0)
    } catch (err) {
      console.log(err);
    }
  }

  const { data: dataResponse, isLoading: loadingData, isFetched: isQueryFetched } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, specification, domainTable, page],
    queryFn: () => ModelService.getAll({ resModel: resModel, specification: specification, domain: domainTable, offset: page * 10 }),
    enabled: !!specification && !!resModel && !!domain,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  })

  useEffect(() => {
    fetchData()
  }, [viewResponse])

  const context = actionData?.context ? evalJSONContext(actionData?.context) : {}

  const { dataSource, columns, visibleOptionalFields } = useTable({
    model: resModel,
    fields: fields?.[`${vid}_${resModel}`],
    data: dataResponse?.records,
    dataModel: viewData?.models?.[resModel],
    context: context
  })

  return (
    <>
      {((!loadingData && isQueryFetched) || isFetching) ? (
        <div className='page-wrapper'>
          <div className='content'>
            <Breadcrumbs
              title={actionData?.name}
              actionData={actionData}
              viewData={viewDataStore}
              dataToolbar={viewDataStore?.views?.tree?.toolbar}
              vid={vid}
              model={resModel}
              isInForm={false}
            />
            <div className='card rounded-[10px] drop-shadow-[0px_1px_8px_rgba(82,88,102,0.06)] overflow-hidden !border-[1.5px] !border-[#DEE7DD]'>
              <div className='card-body flex flex-col gap-[16px]'>
                <div className={`relative w-full overflow-hidden`}>
                  <div className='flex w-full items-center overflow-x-scroll'>
                    <TableView
                      model={resModel}
                      dataSource={dataSource}
                      dataLength={dataResponse?.length}
                      columns={columns}
                      visibleOptionalFields={visibleOptionalFields}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={page}
              totalCount={dataResponse?.length ?? 0}
              pageSize={10}
              onPageChange={page => setPage(page)}
            />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
export default TreeView
