import React from 'react';
import useTabModel from '../model/tab';
import TabView from './tab-view';
import useHandleField from '../model/main';
import TreeViewForm from './tree-view-form';

const TabsView = ({ viewData, onchangeData, resModel, context }) => {
  const { tabsData } = useTabModel(viewData, onchangeData, resModel)

  const getViewInTab = (tab) => {
    if (tab?.type_tab === "form") {
      const { fieldList } = useHandleField({ fields: tab?.fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData })

      return (
        <>
          {fieldList}
        </>
      );
    } else if (tab?.type_tab === "tree") {
      return <TreeViewForm viewData={viewData} resModel={resModel} tab={tab} context={context} />
    }
  }

  return (tabsData?.length > 0 &&
    <>
      {tabsData?.filter((val) => !val.hide).map((tab, index) => {
        const viewInTab = getViewInTab(tab)

        return <TabView name={tab.string} fieldList={viewInTab} active={index === 0} />
      })}
    </>
  )
}

export default TabsView
