import { evalPartialContext } from '../core/domain/context'
import { Domain } from '../core/domain/domain'
import { COMPONENT_TYPE } from './constant/componen-type'
import { FIELD_TYPE } from './constant/field-type'

export const getSpecification = (viewData, resModel) => {
  if (viewData?.models?.[resModel] && resModel) {
    const modelValues = Object.keys(viewData.models[resModel])
    if (modelValues.length > 0) {
      const spectification = modelValues.reduce((acc, item) => {
        acc[item] = {}
        return acc
      }, {})
      return spectification
    } else {
      console.warn('modelValues is empty')
    }
  } else {
    console.warn('listView.models[resModel] or model is undefined')
  }
}

export const getSpecificationByFields = (fields = [], specification = {}, viewData, model) => {
  if (Array.isArray(fields)) {
    let spec = { ...specification }
    fields.forEach((field) => {
      if (!field?.type_co || (field?.name && field?.type_co === COMPONENT_TYPE.FIELD)) {
        if (viewData?.models?.[model]?.[field?.name]) {
          if (viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.ONE2MANY || viewData?.models?.[model]?.[field?.name]?.type === FIELD_TYPE.MANY2MANY) {
            const relation = viewData?.models?.[model]?.[field?.name]?.relation
            const modelRelation = viewData?.models?.[relation]
            if (modelRelation) {
              spec[field?.name] = {
                fields: {},
              }
              spec[field?.name].fields = getSpecificationByFields(field?.fields, {}, viewData, relation)
            }
          } else {
            spec[field?.name] = {}
          }
        } else if (field?.type_tab) {
          const specTab = getSpecificationByFields(field?.fields, spec, viewData, model)
          spec = { ...spec, ...specTab }
        }
      } else if (field?.type_co === COMPONENT_TYPE.GROUP) {
        const specGroup = getSpecificationByFields(field?.fields, spec, viewData, model)
        spec = { ...spec, ...specGroup }
      }
    })
    return spec
  } else {
    console.warn('fields is not array')
  }
}

export const formatCurrency = (amount, currency = 'USD') => {
  if (typeof amount !== 'number' || amount <= 0) {
    return '$0.00'
  }

  const formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  })

  return formatter.format(amount)
}

export function formattedPath(path) {
  const formattedPath = path.replace('/', '').charAt(0).toUpperCase() + path.slice(2)
  const splitFormattedPath = formattedPath?.split('-')
  const showCapitalizedPart = splitFormattedPath[1] ? capitalizeFirstLetter(splitFormattedPath[1]) : ''
  return {
    showCapitalizedPart,
    splitFormattedPath,
  }
}

export function capitalizeFirstLetter(str) {
  if (!str) return str // Kiểm tra nếu chuỗi rỗng
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const createDynamicDomain = (domains) => {
  const result = []
  if (domains?.length > 1 && domains[0]?.length > 0) {
    result.push('&')
    result.push(domains[0])
  } else if (domains[0]?.length > 0) {
    result.push(domains[0])
  }

  for (let i = 1; i < domains.length; i++) {
    if (i < domains.length - 1) result.push('&')

    const { values, name, value, domain } = domains[i]

    if (values?.length > 1) {
      const subOperators = []

      for (let j = 0; j < values.length; j++) {
        j < values.length - 1 && subOperators.push('|')
        subOperators.push([name, 'ilike', values[j]])
      }
      result.push(...subOperators)
    } else {
      if (domain) {
        domain.forEach((subDomain) => {
          result.push(subDomain)
        })
      } else result.push([name, 'ilike', value])
    }
  }

  return result
}

export const parseInputString = (str) => {
  if (!str) return
  const items = str
    .slice(1, -1)
    .split(',')
    .map((item) => item.trim())
  return items.filter((item) => item).map((item) => item.replace(/[()'"]/g, ''))
}

export const clearBeforeToJson = (str) => {
  if (!str) return
  return str.replaceAll('"', "'")
}

export const convertDomainStringToArray = (str) => {
  const jsonString = str
    .replace(/\(/g, '[') // Replace "(" with "["
    .replace(/\)/g, ']') // Replace ")" with "]"
    .replace(/'/g, '"')
    .replace(/False/g, 'false')

  return jsonString
}

export const evalJSONContext = (_context, context = {}) => {
  try {
    return evalPartialContext(_context, context)
  } catch (err) {
    console.log(context, err)
  }
}

export const evalJSONDomain = (domain, context) => {
  try {
    return new Domain(domain).toList(context)
  } catch (err) {
    console.log(err)
  }
}

export function convertStringEmptyToHyphen(str) {
  return str.replace(/\s+/g, '-').toLowerCase()
}

export function checkDivInvoice(str) {
  const check =
    convertStringEmptyToHyphen(str) === 'invoice-lines' || convertStringEmptyToHyphen(str) === 'chi-tiết-hóa-đơn'
  return check
}
