import React from 'react';

const CharField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods = null,
  viewType = '',
  isForm,
  defaultValue
}) => {
  const formProps = methods ? methods.register(name) : {}

  return (
    <input
      {...formProps}
      defaultValue={defaultValue}
      type="text"
      readOnly={readonly}
      placeholder={placeholder}
      required={!invisible && required}
      style={invisible ? { display: 'none' } : {}}
      className={` ${!isForm ? "!bg-transparent focus-within:!border-none" : "field"}`}
    />
  )
}

export default CharField
