import { callPath, updatePath, uploadFilePath } from '../../../util/constant/endpoint'
import requests from '../AxiosClient'

const ModelService = {
  getAll: async ({ resModel, ids = [], specification = {}, domain = [], offset, sort = '' }) => {
    const jsonData = {
      model: resModel,
      method: 'web_search_read',
      ids: ids,
      kwargs: {
        count_limit: 10001,
        domain: domain,
        limit: 10,
        offset: offset,
        order: sort,
        specification: specification,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  saveForm: async ({ resModel, ids = [], data = {}, specification = {}, context = {} }) => {
    const jsonData = {
      model: resModel,
      method: 'web_save',
      with_context: context,
      ids: ids,
      kwargs: {
        vals: data,
        specification: specification,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  deleteByForm: async ({ id, model }) => {
    const jsonData = {
      model: model,
      method: 'unlink',
      ids: id,
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  updateForm: async ({ model, body, id }) => {
    const jsonData = {
      model: model,
      domain: [['id', '=', id]],
      values: body,
    }
    return requests.post(updatePath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getDetailData: async ({ ids = [], model, specification, context }) => {
    const jsonData = {
      model: model,
      method: 'web_read',
      ids: ids,
      with_context: context,
      kwargs: {
        specification: specification,
      },
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  setImage: async (body, queryParams) => {
    const query = new URLSearchParams(queryParams).toString()
    return requests.post(uploadFilePath + '?' + query, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  onChangeForm: async ({ id = [], model, object, specification, context, fieldChange }) => {
    const jsonData = {
      model: model,
      method: 'onchange',
      ids: id,
      with_context: context,
      args: [object ? object : {}, fieldChange ? fieldChange : [], specification],
    }
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },

  parseORMOdoo: (data) => {
    for (const key in data) {
      if (!data[key] && data[key] !== 0) {
        data[key] = false
      }
    }
    return { ...data }
  },
  toDataJS: (data) => {
    for (const key in data) {
      if (data[key] === false) {
        data[key] = null
      }
    }
    return { ...data }
  },
}

export default ModelService
