import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageWithBasePath from '../../general/ImageCustom';
import { checkDivInvoice } from '../../../util/util';

const TabView = (props) => {
    const { name, fieldList, active = false } = props
    const [activeTab, setActiveTab] = useState(active)

    const isInvoiceId = checkDivInvoice(name) ? 'invoice-lines' : ''

    return (
        <div id={isInvoiceId} className={`px-6 py-8 rounded-2xl bg-white flex flex-col gap-8 min-h-20 w-full`}>
            <div className={`h-10 w-full flex justify-between items-center ${activeTab ? 'border-b-[1px] border-[#DEE7DD]' : ''}`}>
                <div className='flex items-center gap-2.5'>
                    <ImageWithBasePath src={"assets/img/icons/inf4.svg"} width={18} height={18} />
                    <div className='text-2xl font-medium text-[#000000]'>{name}</div>
                </div>
                <div className='rounded-full bg-primary-light size-8 flex items-center justify-center cursor-pointer select-none'
                    onClick={() => setActiveTab(!activeTab)}
                >
                    <ImageWithBasePath src={"assets/img/icons/arrow.svg"} width={12} height={6} />
                </div>
            </div>

            <div className={`w-full grid grid-cols-2 transition-all duration-1000 ${!activeTab ? 'hidden' : ''} `}>
                {fieldList}
            </div>
        </div>
    )
}

TabView.propTypes = {
    name: PropTypes.string,
    fieldList: PropTypes.object,
    active: PropTypes.bool,
}

export default TabView