import React from 'react'
import ImageWithBasePath from '../../general/ImageCustom'
import useTableStore from '../../../store/table'

const ActionCheckbox = (props) => {
  const { setApprove, dataToolbar, openModal, setOpenImport, setOpenModal } = props
  const { selectedRowKeys, setSelectedRowKeys } = useTableStore()
  const handleClick = (id) => {
    if (id == openModal) {
      setOpenModal(0)
    } else {
      setOpenModal(id)
    }
    setOpenImport(false)
  }
  return (
    <div className='flex items-center gap-[8px]'>
      <div className='flex items-center gap-[8px] rounded-[8px] bg-[#d4ebfa] px-[16px] py-[8px]'>
        {selectedRowKeys?.length} selected
        <button onClick={() => setSelectedRowKeys([])}>
          <ImageWithBasePath src='assets/img/close-icon.svg' alt='close icon' className='h-4' />
        </button>
      </div>
      <div
        onClick={() => handleClick(1)}
        className='relative flex cursor-pointer items-center gap-[8px] rounded-[8px] bg-[#dee2e6] px-[16px] py-[8px]'
      >
        <ImageWithBasePath src='assets/img/print.svg' alt='setting' className='h-4' />
        Print
        {openModal == '1' && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='absolute left-[-190px] top-[37px] z-[5] flex h-max max-h-[400px] w-[280px] flex-col gap-[6px] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#ebeaea] bg-[#fff] shadow-sm'
          >
            <div className='h-full w-full overflow-y-scroll'>
              {dataToolbar?.print.map((item) => {
                return (
                  <div key={item.id} className='px-[24px] py-[12px] hover:bg-[#f6f4f4]'>
                    {item.name}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div
        onClick={() => handleClick(2)}
        className='relative flex cursor-pointer items-center rounded-[8px] bg-[#dee2e6] px-[16px] py-[8px]'
      >
        <ImageWithBasePath src='assets/img/setting.svg' className='h-6' alt='setting' />
        Action
        {openModal == 2 && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='absolute left-[-190px] top-[37px] z-[5] flex h-max max-h-[400px] w-[280px] flex-col gap-[6px] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#ebeaea] bg-[#fff] shadow-sm'
          >
            <div className='h-full w-full overflow-y-scroll'>
              <button
                onClick={() => setApprove(true)}
                className='w-full px-[24px] py-[12px] text-left hover:bg-[#f6f4f4]'
              >
                Delete
              </button>
              {props?.dataToolbar?.action.map((item) => {
                return (
                  <div key={item.id} className='px-[24px] py-[12px] text-left hover:bg-[#f6f4f4]'>
                    {item.name}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ActionCheckbox
