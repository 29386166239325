import React, { useState, useEffect } from 'react'
import ViewServices from '../../../../core/services/view-service/view-service'
import { useSearchParams } from 'react-router-dom'
import ImageWithBasePath from '../../../general/ImageCustom'
import { useClickOutside } from '../../../../hooks/useClickOutside'
import { createDynamicDomain, getSpecification, parseInputString } from '../../../../util/util'
import PopupFilter from '../PopupFilter'
import useSearch from '../../model/search'
import SearchList from './search-list'
import TagSearch from './tag-search'
import useSearchStore from '../../../../store/search'
import useTableStore from '../../../../store/table'
import { useTranslation } from 'react-i18next'
import useHeaderStore from '../../../../store/header'

const Search = ({ actionData, viewData }) => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get('vid')
  const [showPopupFilter, setShowPopupFilter] = useState(false)
  const [filterBy, setFilterBy] = useState([])
  const [groupBy, setGroupBy] = useState([])
  const [specification, setSpecification] = useState()
  const [firstDomain, setFirstDomain] = useState()
  const { setTableHead, searchString, selectedTags } = useSearchStore()
  const { handleAddTagSearch, onKeyDown, onChangeSearchInput } = useSearch()
  const { setDomainTable } = useTableStore()
  const { t } = useTranslation()
  const { lang } = useHeaderStore()

  const popupFilterRef = useClickOutside(() => {
    setShowPopupFilter(false)
  })

  const fetchSearchResult = async () => {
    const domainFormat = createDynamicDomain([firstDomain, ...selectedTags])
    setDomainTable(domainFormat)
  }

  const fetchData = async () => {
    try {
      const { res_model: model, search_view_id: searchView, views = [], domain } = actionData || {};
      views.push([searchView, 'search']);

      const searchRes = await ViewServices.getFieldView({ id: Number(vid), resModel: model, views: views, lang: lang });
      const dataModel = searchRes?.models?.[model];
      const searchViews = searchRes?.views?.search;

      const heading = searchViews?.search_by
        ?.filter((item) => !item.invisible)
        ?.map(({ name }) => ({
          dataIndex: name,
          title: dataModel[name]?.string,
          name: dataModel[name]?.name,
        }));

      setSpecification(getSpecification(viewData, model));
      setFirstDomain(parseInputString(domain) ?? []);
      setTableHead(heading);
      setFilterBy(searchViews?.filter_by.filter((item) => !item.invisible && item.domain));
      setGroupBy(searchViews?.group_by.filter((item) => !item.invisible));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [vid])

  useEffect(() => {
    if (specification) {
      fetchSearchResult()
    }
  }, [selectedTags])

  return (
    <div className='custom-search-input relative flex items-center rounded-[8px] border-[1px] border-solid border-[#c6c5c5]'>
      <div className='relative flex min-w-[400px] max-w-[600px] flex-wrap items-center gap-[8px] border-r-[1px] border-solid border-[#c6c5c5] px-2 py-1'>
        <div className='h-[16px] w-[16px]'>
          <ImageWithBasePath src='assets/img/search-icon.svg' alt='search icon' />
        </div>
        <div className='relative flex flex-1 flex-wrap items-center gap-[8px]'>
          <TagSearch />
          <input
            value={searchString}
            className='min-h-[25px] w-auto flex-1 border-none bg-transparent outline-none'
            placeholder={t("search...")}
            onChange={(e) => {
              onChangeSearchInput(e.target.value)
              setShowPopupFilter(false)
            }}
            onKeyDown={onKeyDown}
          />
          <SearchList />
        </div>
      </div>
      <div
        className={`flex h-full cursor-pointer items-center justify-center px-2`}
        onClick={() => {
          setShowPopupFilter(true)
        }}
      >
        <button>
          <ImageWithBasePath src='assets/img/arrow-down.svg' alt='arrow down' className='h-4' />
        </button>
      </div>
      {showPopupFilter && (
        <div ref={popupFilterRef}>
          <PopupFilter filterBy={filterBy} groupBy={groupBy} handleAddTag={handleAddTagSearch} />
        </div>
      )}
    </div>
  )
}

export default Search
