import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, Outlet, useLocation } from 'react-router-dom'
import { pagesRoute, publicRoutes } from './router.link'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import { CookieStorage } from '../util/lib/Cookies'
import Header from '../components/header'
import Sidebar from '../components/header/SideBar'
import ThemeSettings from '../components/header/ThemeSettings'
import MetadataManager from '../metadata/MetadataManager'
import StoreService from '../core/services/company/StoreService'
import { MetaProvider } from './MetaContext'
import '../style/css/color.css'
import { useTranslation } from 'react-i18next'

const HeaderLayout = () => {
  const data = useSelector((state) => state.toggle_header)
  const menuFocus = useSelector((state) => state.menu_focus)

  return (
    <div className={`main-wrapper ${data ? 'header-collapse' : ''}`}>
      <Header />
      <Sidebar />
      <Outlet key={menuFocus} />
      <ThemeSettings />
    </div>
  )
}
const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header)
  const location = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  useEffect(() => {
    Cookies.set('i18next', 'vi')
    i18n.changeLanguage("vi");
    const hasAccessToken = () => {
      const token = CookieStorage.getAccessToken()
      return !!token
    }

    if (!hasAccessToken()) {
      navigate('/signin')
    } else {
      if (location.pathname === '/') {
        navigate('/list?vid=332&model=account.move')
      }
    }
  }, [])

  const Authpages = () => (
    <div className={`${data ? 'header-collapse' : ''}`}>
      <Outlet />
      {/* <Loader /> */}
      <ThemeSettings />
    </div>
  )
  const [id, setId] = useState(null)
  const [metadata, setMetadata] = useState(null)
  useEffect(() => {
    console.log('metadata', metadata)
    if (metadata && metadata.primary_color) {
      console.log(
        "document.documentElement.style.setProperty('--primary', metadata.primary_color);",
        // document.documentElement.style.setProperty('--primary', metadata.primary_color)
        document.documentElement.style.setProperty('--primary', "#0B701D")
      )
      const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary')
    } else {
      console.error('metadata or primary_color is null or undefined')
      // Set a default color if primary_color is not available
      // document.documentElement.style.setProperty("--primary", "#000000"); // Example fallback color
    }
  }, [metadata])

  const [loadingImport, setLoadingImport] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [errorData, setErrorData] = useState()
  const accessToken = CookieStorage.getAccessToken()
  const fetchCurrentCompany = async () => {
    await StoreService.getCurrentCompany().then((res) => {
      setId(res?.current_company_id)
    })
  }
  const handleLoadingImport = (action) => {
    setLoadingImport(action)
  }
  const getUserCompany = async (companyId) => {
    await StoreService.getUserCompany(companyId).then((res) => {
      setMetadata(res?.records[0])
    })
  }
  useEffect(() => {
    accessToken && fetchCurrentCompany()
    if (id && accessToken) {
      getUserCompany(id)
    }
  }, [id, accessToken])
  return (
    <div className='App'>
      <MetaProvider
        metadata={metadata}
        errorData={errorData}
        setErrorData={setErrorData}
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        loadingImport={loadingImport}
        setLoadingImport={handleLoadingImport}
      >
        {metadata && <MetadataManager metadata={metadata} />}
        <Routes>
          <Route path={'/'} element={<HeaderLayout />}>
            {pagesRoute.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
          <Route path={'/'} element={<Authpages />}>
            {publicRoutes.map((route, id) => (
              <Route path={route.path} element={route.element} key={id} />
            ))}
          </Route>
        </Routes>
      </MetaProvider>
    </div>
  )
}
export default AllRoutes
