import React, { useEffect } from 'react';

const RadioButtonGroup = ({
  name,
  modelsData,
  readonly,
  required,
  register,
  setValue,
  placeholder,
  invisible,
  methods,
  selection
}) => {

  const formProps = methods ? methods.register(name) : {}
  useEffect(() => {

    if (selection?.length > 0) {
      if (setValue) setValue(name, selection?.[0]?.[0])
    }
  }, [selection, name, setValue])

  return (
    <div className='flex flex-col'>
      {selection?.map((select, index) => (
        <div className='flex items-center gap-1' key={select[0]}>
          <input
            type='radio'
            {...formProps}
            id={select[0]}
            value={select[0]}
            readOnly={readonly}
            required={!invisible && required}
            placeholder={placeholder}
          />
          <label htmlFor={select[0]}>{select[1]}</label>
        </div>
      ))}
    </div>
  )
}

export default RadioButtonGroup
