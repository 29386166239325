import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { all_routes } from '../../Router/all_routes'
import { SidebarContext } from '../../util/context/SidebarContext'
import ButtonLoading from '../../components/general/ButtonLoading'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import alertify from 'alertifyjs'

const ForgotPassword = () => {
  const { isLoading } = useContext(SidebarContext)
  const route = all_routes
  const { register, handleSubmit } = useForm()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    UserService.forgotPassword(data)
      .then((res) => {
        alertify.set('notifier', 'position', 'top-right')
        alertify.success('Forgot password successfully')
      })
      .catch((err) => {
        console.error('🚀 ~ onSubmit ~ err:', err)
        alertify.set('notifier', 'position', 'top-right')
        alertify.error(err?.detail || 'Forgot password failed')
      })
  }

  return (
    <div className='main-wrapper'>
      <div className='account-content'>
        <div className='login-wrapper bg-img'>
          <div className='login-content'>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '70%' }}>
              <div className='login-userset'>
                <div style={{ width: '200px !important' }} className='login-logo logo-normal'>
                  <ImageWithBasePath src='assets/logo/vitrust-red.png' alt='img' />
                </div>
                <Link to={route.dashboard} className='login-logo logo-white'>
                  <ImageWithBasePath src='assets/img/logo-white.svg' alt />
                </Link>
                <div className='login-userheading'>
                  <h3>Forgot Password</h3>
                  <h4>Please enter the email address you would like your password reset information to be sent to</h4>
                </div>
                <div className='form-login mb-3'>
                  <label className='form-label'>Account</label>
                  <div className='form-addons'>
                    <input
                      {...register('email')}
                      placeholder='Enter your email address'
                      type='text'
                      className='form-control'
                    />
                    <ImageWithBasePath src='assets/img/icons/mail.svg' alt='img' />
                  </div>
                </div>
                <div className='form-login'>
                  <ButtonLoading loading={isLoading} className={'btn-login'} content={'Send'} />
                </div>
                <button
                  onClick={() => navigate('/signin')}
                  style={{
                    color: '#232323',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    padding: '16px 8px',
                    borderRadius: '10px',
                    background: '#fff',
                    border: '1px solid #E3E3E3',
                    width: '100%',
                  }}
                >
                  Back to Sign In
                </button>
              </div>
            </form>
          </div>
          <div className='login-content'>
            <ImageWithBasePath src='assets/img/main-signin-edu.jpg' alt='img' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
