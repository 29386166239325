import React from 'react';
import PropTypes from 'prop-types';
import TableView from '../../table/view/table-view';
import useTableStore from '../../../store/table';
import useTable from '../../table/model/table';
import ModelService from '../../../core/services/model-service/model-service';
import { evalJSONContext, getSpecificationByFields } from '../../../util/util';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

const TreeViewForm = ({ viewData, resModel, tab, context }) => {
    const [searchParams] = useSearchParams()
    const vid = searchParams.get("vid")
    const { watch, control } = useFormContext()
    const formValues = watch()
    const { fields } = useTableStore()
    const relation = viewData?.models?.[resModel]?.[tab?.name]?.relation
    const relationField = viewData?.models?.[resModel]?.[tab?.name]?.relation_field
    const contextTab = tab?.context ? { ...evalJSONContext(tab?.context, { ...formValues }), ...evalJSONContext(context, { ...formValues }) } : {}
    const objVal = {
        [relationField]: formValues
    }
    const { columns, visibleOptionalFields } = useTable({
        context: formValues,
        fields: fields?.[`${vid}_${relation}`] || tab.fields,
        data: formValues?.[tab?.name],
        dataModel: viewData?.models?.[relation],
        model: relation
    })
    const specification = getSpecificationByFields(tab?.fields, {}, viewData, relation)

    const { fields: fieldsArray, append } = useFieldArray({
        control,
        name: tab.name
    });


    const handleAddLine = async (id) => {
        try {
            const onchangeReponse = await ModelService.onChangeForm({
                ids: id ? [id] : [],
                model: relation,
                specification: specification,
                context: contextTab,
                object: objVal
            })

            if (onchangeReponse?.value) {
                append(onchangeReponse?.value)
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="col-span-2">
            <TableView
                rootField={viewData?.models?.[resModel]?.[tab?.name]}
                funcAddLine={handleAddLine}
                model={relation}
                isForm={true}
                dataSource={fieldsArray}
                columns={columns}
                dataLength={fieldsArray.length}
                visibleOptionalFields={visibleOptionalFields} />
        </div>
    )
}

TreeViewForm.propTypes = {
    viewData: PropTypes.object,
    onchangeData: PropTypes.object,
    resModel: PropTypes.string
}

export default TreeViewForm