import alertify from 'alertifyjs'
import { useContext } from 'react'
import SettingsService from '../core/services/SettingsService'
import { useTranslation } from 'react-i18next'
import { SidebarContext } from '../util/context/SidebarContext'
import UserService from '../core/services/auth'
import { CookieStorage } from '../util/lib/Cookies'

const useLoginSubmit = ({ navigate, isRemember }) => {
  const { setIsLoading } = useContext(SidebarContext)
  const { t } = useTranslation()
  const onSubmit = (data) => {
    setIsLoading(true)
    UserService.login(data)
      .then((res) => {
        CookieStorage.setToken(res.access_token)
        isRemember && CookieStorage.setRefreshToken(res.refresh_token)
        alertify.set('notifier', 'position', 'top-right')
        alertify.success(t('success_sign_in'))
        SettingsService.loadConfig()
          .then((res) => {
            CookieStorage.setSetting(res.value)
          })
          .catch((err) => console.error(err))

        setIsLoading(false)
        navigate('/list?vid=511&model=res.partner')
      })
      .catch((err) => {
        setIsLoading(false)
        navigate('/signin')
        alertify.set('notifier', 'position', 'top-right')
        alertify.error(err?.detail || t('error_sign_in'))
      })
  }
  return { onSubmit }
}

export default useLoginSubmit
