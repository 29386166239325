import React from "react";
import TableHead from "./table-head";
import TableBody from "./table-body";
import TableFooter from "./table-footer";

const TableView = ({ dataSource, columns, visibleOptionalFields, model, isForm = false, funcAddLine, rootField, dataLength }) => {
    console.log("🚀 ~ TableView ~ dataSource:", dataSource)
    return (
        <div className="w-full max-w-full overflow-scroll">
            <table className="bg-white relative w-full">
                <TableHead model={model} isForm={isForm} columns={columns} dataSource={dataSource} visibleOptionalFields={visibleOptionalFields} />
                <TableBody model={model} columns={columns} isForm={isForm} dataSource={dataSource} rootField={rootField} dataLength={dataLength} />
                {isForm && <TableFooter funcAddLine={funcAddLine} />}
            </table>

        </div>
    );
};

export default TableView;
