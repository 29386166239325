import React, { useState } from 'react'
import ImageWithBasePath from '../../general/ImageCustom'
import useTableStore from '../../../store/table'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { useSearchParams } from 'react-router-dom'

const TableFilter = ({ model, visibleOptionalFields = [] }) => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get("vid")
  const { fields, setFields } = useTableStore()
  const [openModal, setOpenModal] = useState(false)

  const filterRef = useClickOutside(() => {
    setOpenModal(false)
  })

  const handleClick = (data) => {
    const optional = data?.optional === 'show' ? 'hide' : 'show'

    const newData = (fields[`${vid}_${model}`] ??= []).map((item) => (item.name === data.name ? { ...item, optional: optional } : item))
    setFields({ ...fields, [`${vid}_${model}`]: newData })
  }

  return (
    <div ref={filterRef} className='w-[25px] relative ms-auto'>
      <button type='button' onClick={() => setOpenModal(!openModal)}>
        <ImageWithBasePath src='assets/img/filter-icon.svg' alt='filter icon' className='h-4' />
      </button>
      {openModal && (
        <div className='absolute left-[-230px] z-[9999] flex w-[250px] flex-col gap-[16px] overflow-y-scroll rounded-[8px] bg-[#fff] px-[24px] py-[16px] shadow-md'>
          {visibleOptionalFields?.map((item) => {
            return (
              <label
                htmlFor={`${item.name + model}`}
                key={item.name}
                className='flex items-center gap-[8px]'
                onClick={() => handleClick(item)}
              >
                <input type='checkbox' id={`${item.name}`} checked={item.optional != 'hide'} />
                {item.string}
              </label>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TableFilter
