import React, { useId, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { IoDocumentOutline } from 'react-icons/io5'

const FileUploadDownload = ({ readonly, placeholder, required, invisible }) => {
  const inputId = useId()
  const [file, setFile] = useState(null)

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleFileDownload = () => {
    const url = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className=''>
      <label
        htmlFor={inputId}
        className='flex cursor-pointer items-center justify-center rounded-lg border-2 border-gray-300 bg-white px-3 py-2 transition-colors duration-300 hover:bg-gray-200'
      >
        {file ? (
          <div className='flex items-center gap-2'>
            <IoDocumentOutline className='text-green-500' size={20} />
            <span className='cursor-pointer text-lg'>{file.name}</span>
          </div>
        ) : (
          <span className='cursor-pointer text-lg'>{placeholder || 'Upload File'}</span>
        )}
        <input
          id={inputId}
          type='file'
          onChange={handleFileChange}
          className='hidden'
          readOnly={readonly}
          required={!invisible && required}
        />
      </label>
      {file && (
        <button
          onClick={handleFileDownload}
          className='mt-2 rounded-lg bg-blue-500 px-4 py-2 text-white transition-colors duration-300 hover:bg-blue-600'
          disabled={readonly}
        >
          Download File
        </button>
      )}
    </div>
  )
}

export default FileUploadDownload
