import React from 'react';
import useCheckbox from '../model/checkbox';
import useTableStore from '../../../store/table';
import FieldView from '../../field/field-view';
import ModelService from '../../../core/services/model-service/model-service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { VIEW_TYPE } from '../../../util/constant/view-type';
import ImageWithBasePath from '../../general/ImageCustom';

const TableBody = ({ columns, dataSource, isForm, rootField, model, dataLength }) => {
    const { selectedRowKeys, setSelectedRowKeys } = useTableStore();
    const { handleCheckboxChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const handleUpdate = (recordId) => {
        if (!isForm) {
            searchParams.set("id", recordId)
            const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
            navigate(_url)
        }
    }

    return (
        <tbody>
            {(dataSource && dataLength > 0) ? dataSource?.map((record, indexRow) => (
                <tr
                    key={record.id}
                    className={`border-b border-[#e8e8e8] ${selectedRowKeys.includes(record.id)
                        ? "bg-blue-100 hover:bg-blue-200"
                        : "hover:bg-gray-50"
                        } `}
                >
                    <td className={`${isForm ? 'hidden' : ''} w-12 cursor-pointer whitespace-nowrap text-sm font-medium text-gray-900 p-3`}>
                        <input
                            type="checkbox"
                            checked={selectedRowKeys.includes(record.id)}
                            onChange={(event) => handleCheckboxChange(event, record.id)}
                            className='align-middle ml-2'
                        />
                    </td>
                    {
                        columns?.map((col) => {
                            console.log("🚀 ~ columns?.map ~ col:", col)
                            return <td
                                key={col.key}
                                className="p-3 text-sm w-max whitespace-nowrap font-normal text-gray-900 border-b border-gray-200"
                                onClick={() => handleUpdate(record.id)}
                            >
                                <FieldView {...col.field} rootField={rootField} index={indexRow} defaultValue={record[col.key]} onchangeData={ModelService.toDataJS(record)} string="" isForm={isForm} />
                            </td>
                        })
                    }
                    <td></td>
                </tr>
            )) : !isForm &&
            <tr>
                <td className='w-full' colSpan={columns?.length + 2}>
                    <div className='my-20 flex flex-col gap-3 justify-center items-center'>
                        <ImageWithBasePath src="assets/img/empty_document.svg" alt="empty_document.svg" width={120} height={140} className="w-[120px] h-[140px]" />
                        <div className='text-xl font-extrabold'>Create new document</div>
                    </div>
                </td>
            </tr>
            }
        </tbody>
    )
}

export default TableBody