import React from 'react';
import { useTranslation } from 'react-i18next';

const TableFooter = ({ funcAddLine }) => {
    const { t } = useTranslation()
    return (
        <tfoot>
            <div>
                <button type='button' onClick={() => funcAddLine()} className='button-primary my-2'>{t('add_line')}</button>
            </div>
        </tfoot>
    )
}

export default TableFooter