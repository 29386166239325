import React from 'react';
import { DOTS, usePagination } from '../../hooks/usePagination';

const Arrow = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
}

const Pagination = props => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage < 0 || paginationRange.length < 1) {
        return null;
    }

    const onNext = () => {
        if (currentPage < lastPage - 1) {
            onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage > 0) {
            onPageChange(currentPage - 1);
        }
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className="pagination-container rounded-lg px-3 py-4 mt-6"
        >
            <li
                className={`pagination-item ${currentPage === 0 && "disabled"}`}
                onClick={onPrevious}
            >
                <div className="arrow left">
                    <Arrow />
                </div>
            </li>
            {paginationRange.map(pageNumber => {
                if (pageNumber === DOTS) {
                    return <li className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li
                        className={`pagination-item ${pageNumber - 1 === currentPage && "selected"}`}
                        onClick={() => onPageChange((pageNumber - 1))}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={`pagination-item ${currentPage + 1 === lastPage && "disabled"}`}
                onClick={onNext}
            >
                <div className="arrow right">
                    <Arrow />
                </div>
            </li>
        </ul>
    );
};

export default Pagination;
