import React from 'react';
import { formatCurrency } from '../../util/util';

const IntegerField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods = null,
  viewType = '',
  isForm,
  defaultValue
}) => {
  const formProps = methods ? methods.register(name) : {}
  const valueFormat = formatCurrency(parseFloat(value), "VND")

  if (!isForm) {
    return <span>{valueFormat}</span>
  }

  return (
    <input
      {...formProps}
      defaultValue={valueFormat}
      type="text"
      readOnly={isForm ? readonly : true}
      placeholder={placeholder}
      required={!invisible && required}
      style={invisible ? { display: 'none' } : {}}
      className={` ${!isForm ? "!bg-transparent focus-within:!border-none" : "field"}`}
    />
  )
}

export default IntegerField
