import { useLocation, useNavigate } from 'react-router-dom'

const useToolBar = (vid, model) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location
    const baseUrl = pathname.replace(/^\/?(new-|update-)?/, '')

    const handleAddNew = () => {
        navigate(`/form?vid=${vid}&model=${model}`)
    }

    const handleBack = () => {
        navigate(`/list?vid=${vid}&model=${model}`);
    }

    const isShow = !(pathname === "/list")

    const onClick = isShow ? handleBack : handleAddNew

    return {
        handleAddNew,
        handleBack,
        baseUrl,
        pathname,
        isShow,
        onClick
    }
}

export default useToolBar
