import React from 'react'

import PropTypes from 'prop-types'

import Loader from 'react-js-loader'
import { useMetaContext } from '../../Router/MetaContext'
const Loading = ({ marginLeft = '0px' }) => {
  const { metadata } = useMetaContext()
  return (
    <div style={{ marginTop: '100px', marginLeft: marginLeft }}>
      <Loader
        type='ekvalayzer'
        color={metadata?.primary_color ?? '#ED1C24'}
        bgColor={metadata?.primary_color ?? '#ED1C24'}
        size={100}
      />
    </div>
  )
}

Loading.propTypes = {
  marginLeft: PropTypes.string,
}

export default Loading
