import React from 'react'
import BinaryField from './BinaryField'
import DateField from './DateField'
import FileUploadDownload from './FileUploadDownload'
import InputField from './InputField'
import Many2ManyCheckbox from './Many2ManyCheckbox'
import Many2ManyTag from './Many2ManyTag'
import Many2OneButton from './Many2OneButton'
import Many2OneField from './Many2OneField'
import RadioButtonGroup from './RadioButtonGroup'
import TextAreaField from './TextAreaField'
import SelectDropdown from './SelectDropdown'
import CharField from '../instance-fields/CharField'
import FloatField from '../instance-fields/FloatField'
import IntegerField from '../instance-fields/IntegerField'
import QrCode from './QrCode'
import ButtonBadge from './ButtonBadge'

const RenderWidget = (props) => {
  const {
    name,
    setValue,
    relation,
    onchangeData,
    modelsData,
    type,
    invisible,
    widget,
    value,
    string,
    methods,
    placeholder,
    required,
    readonly,
    defaultValue
  } = props

  switch (type) {
    case 'float':
      return (
        <FloatField {...props} ></FloatField>
      )
    case 'char':
      if (widget === "image_url") {
        return (<QrCode value={value} string={string} />)
      }
      return (
        <CharField {...props} ></CharField>
      )
    case 'integer':
      return (
        <IntegerField {...props} ></IntegerField>
      )
    case 'monetary':
      return (
        <IntegerField {...props} ></IntegerField>
      )
    case 'html':
      return (
        <InputField
          type={type}
          name={name}
          methods={methods}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          onchangeData={onchangeData}
          invisible={invisible}
        />
      )
    case 'date':
      return (
        <DateField
          {...props}
        />
      )
    case 'datetime':
      return (
        <DateField
          {...props}
        />
      )
    case 'many2one':
      return (
        <Many2OneField
          {...props}
          relation={relation}
          name={name}
          methods={methods}
          onchangeData={onchangeData}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
          defaultValue={defaultValue}
        />
      )
    case 'many2one_checkbox':
      return (
        <Many2ManyCheckbox
          relation={relation}
          name={name}
          methods={methods}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
        />
      )
    case 'many2one_button':
      return (
        <Many2OneButton
          {...props}
        />
      )
    case 'many2many':
      if (widget === 'many2many_tags') {
        return (
          <Many2ManyTag
            {...props}
          />
        )
      }
      return <div />
    case 'file_upload_download':
      return (
        <FileUploadDownload
          relation={relation}
          name={name}
          methods={methods}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
        />
      )
    case 'selection':
      if (widget === 'radio') {
        return (
          <RadioButtonGroup
            name={name}
            methods={methods}
            modelsData={modelsData}
            readonly={readonly}
            placeholder={placeholder}
            required={required}
            setValue={setValue}
            invisible={invisible}
          />
        )
      }
      if (widget === "badge") {
        return <ButtonBadge {...props} />
      }
      return (
        <SelectDropdown
          {...props}
          name={name}
          methods={methods}
          modelsData={modelsData}
          onchangeData={onchangeData}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
          defaultValue={defaultValue}
        />
      )
    case 'binary':
      if (widget === 'payment') {
        return <></>
      }
      return (
        <BinaryField
          name={name}
          methods={methods}
          onchangeData={onchangeData}
          readonly={placeholder}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
        />
      )
    case 'text':
      return (
        <TextAreaField
          {...props}
        />
      )
    default:
      return null
  }
}

export default RenderWidget
