import ImageWithBasePath from '../../general/ImageCustom'
import useToolBarStore from '../../../store/toolbar'
import { useClickOutside } from '../../../hooks/useClickOutside'

const LeftToolbar = (props) => {
  const { dataToolbar, isShow, openImport, setOpenImport, setOpenModal, onClick, title, subTitle, actions } = props
  const { resModel } = useToolBarStore()
  const actionRef = useClickOutside(() => {
    setOpenImport(false)
  })

  const url_icon = JSON.parse(sessionStorage.getItem("url_icon")) ?? {}
  const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon")) ?? {}

  const existIcon = url_icon?.url_icon ? url_icon : parent_icon

  return (
    <div className='add-item flex items-center gap-[8px]'>
      <div className='page-title flex items-center'>
        <div className="icon me-4 p-2 border border-[#E2E4E9] rounded-full flex justify-center items-center bg-primary w-12 h-12">
          {/* <ImageWithBasePath src="assets/img/iconcum.svg" width={24} height={24} alt="" /> */}
          <img
            src={existIcon?.url_icon && existIcon?.url_icon?.startsWith("http") ? existIcon.url_icon : process.env.REACT_APP_DOMAIN + existIcon.url_icon}
            width={20}
            height={20}
            alt="img"
          />
        </div>
        <div className="group-title">
          <div className='title text-[20px] text-dark font-semibold'>{title}</div>
          <div className='sub-title text-[20px] text-primary'>{subTitle}</div>
        </div>
      </div>
      {!isShow && (
        <button
          ref={actionRef}
          onClick={() => {
            setOpenImport(!openImport), setOpenModal(0)
          }}
          className='relative'
        >

          <ImageWithBasePath src='assets/img/setting.svg' className='h-6' alt='setting' />
          {openImport && (
            <div
              onClick={(e) => e.stopPropagation()}
              className='absolute right-[-280px] top-[20px] z-[5] flex w-[300px] flex-col gap-[8px] rounded-[8px] border-[1px] border-solid border-[#efeeee] bg-[#fff] p-[16px] py-[8px] shadow-md'
            >
              {/* {dataToolbar?.root?.import && <UploadButton resModel={resModel} />} */}
              {actions &&
                actions.map((item) => {
                  return (
                    <button
                      key={item.id}
                      className='w-full cursor-pointer rounded-[8px] bg-transparent px-[16px] py-[8px] text-left text-[14px] font-semibold leading-[24px] text-[#333] hover:bg-[#d3d1d1]'
                    >
                      {item.name}
                    </button>
                  )
                })}
            </div>
          )}
        </button>
      )}
    </div>
  )
}

export default LeftToolbar
