import StudentService from '../../core/services/model-service/model-service'
import alertify from 'alertifyjs'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ImageWithBasePath from '../general/ImageCustom'

const ModalDelete = ({ setApprove, selectedRowKeys, resModel }) => {
  const MySwal = withReactContent(Swal)
  const deleteByForm = () => {
    setApprove(0)
    return StudentService.deleteByForm({
      id: selectedRowKeys,
      model: resModel,
    }).then((res) => {
      alertify.set('notifier', 'position', 'top-right')
      alertify.success(`Delete Successfully`)
      MySwal.fire({
        title: 'Deleted!',
        text: 'Your file has been deleted.',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      location.reload()
    })
  }
  return (
    <div className='fixed inset-0 z-[50] flex items-center justify-center bg-[rgba(0,0,0,0.8)]'>
      <div className='relative flex flex-col gap-[24px] rounded-[8px] bg-[#fff] px-[24px] py-[24px] shadow-sm'>
        <button onClick={() => setApprove(false)} className='absolute right-[8px] top-[8px]'>
          <ImageWithBasePath src='assets/img/close-icon.svg' alt='close icon' className='h-5 w-5' />
        </button>
        <div className='text-[24px] font-bold leading-[32px]'>Do you really want to delete?</div>
        <div className='flex items-center justify-center gap-[32px]'>
          <button
            onClick={deleteByForm}
            className='confirm-delete-breadcrumb rounded-[8px] px-[24px] py-[8px] font-bold text-[#fff] shadow-md'
          >
            Yes
          </button>
          <button className='rounded-[8px] px-[24px] py-[8px] shadow-md'>No</button>
        </div>
      </div>
    </div>
  )
}
export default ModalDelete
