import React, { useState } from 'react'
import Cookies from 'js-cookie'
import ImageWithBasePath from '../general/ImageCustom'
import { useClickOutside } from '../../hooks/useClickOutside'
import useHeaderStore from '../../store/header'
import { useTranslation } from 'react-i18next'

const SwitchLanguage = () => {
    const languages = [
        {
            name: "VIE",
            code: "vi_VN"
        },
        {
            name: "ENG",
            code: "en_US"
        }
    ]
    const [toggleDropDownLanguages, setToggleDropDownLanguages] = useState(false)
    const { i18n } = useTranslation()
    const { lang, setLang } = useHeaderStore()
    const switchLanguageRef = useClickOutside(() => {
        setToggleDropDownLanguages(false)
    })

    const handleLanguageChange = (lang) => {
        const code = lang?.code
        const codeLangLowerCase = String(code).split("_")[0]
        Cookies.set('i18next', codeLangLowerCase)
        i18n.changeLanguage(codeLangLowerCase);
        setLang(code);
    };

    return (
        <div ref={switchLanguageRef} onClick={() => setToggleDropDownLanguages(!toggleDropDownLanguages)} className="flex items-center gap-2 py-1 px-3 rounded-lg border border-gray-primary cursor-pointer relative">
            <span className="text-xs leading-5 font-bold">{languages.find((item) => item.code === lang).name}</span>
            <ImageWithBasePath src="assets/img/header/chevrondown.svg" alt="down" width={16} height={16} />
            {toggleDropDownLanguages && <div className="absolute left-0 z-50 w-full rounded-lg border border-gray-primary bg-white top-[calc(100%_+_4px)]">
                {
                    languages.map((lang, index) => (
                        <div key={`lang-${index}`} onClick={() => handleLanguageChange(lang)} className="language-item text-xs leading-5 font-bold select-item hover:bg-[#08813C10] px-3 py-1 transition-all">{lang.name}</div>
                    ))
                }
            </div>}
        </div>
    )
}

export default SwitchLanguage
