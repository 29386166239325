import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'

const TextAreaField = ({ methods, name, readonly, placeHolder, required, invisible, isForm, value }) => {
  const formProps = methods ? methods.register(name) : {}

  if (!isForm) {
    return <span>{value}</span>
  }

  return (
    <textarea
      {...formProps}
      className='h-full min-h w-full cursor-pointer rounded-lg border-[1px] border-solid border-[#E4E4E7] bg-[#fff] p-2'
      readOnly={readonly}
      placeholder={placeHolder}
      required={!invisible && required}
    />
  )
}

export default TextAreaField
