import useSearchStore from "../../../store/search";

const useSearch = () => {
    const { tableHead, searchString, setSearchString, hoveredIndexSearchList, setHoveredIndexSearchList, setSelectedTags, selectedTags } = useSearchStore(state => state)

    const setTagSearch = (tag) => {
        const { name, value, icon } = tag;

        if (icon) {
            const alreadyExists = selectedTags.some(
                (filter) => filter.values.includes(value) && filter.icon
            );

            if (alreadyExists) {
                const updatedTags = selectedTags
                    .map((filter) =>
                        filter.name === name
                            ? { ...filter, values: filter.values.filter((val) => val !== value) }
                            : filter
                    )
                    .filter((filter) => filter.values.length > 0);

                setSelectedTags(updatedTags);
            } else {
                const existingFilter = selectedTags.find((filter) => filter.name === name);
                if (existingFilter) {
                    const updatedTags = selectedTags.map((filter) =>
                        filter.name === name
                            ? { ...filter, values: [...filter.values, value], icon }
                            : filter
                    );

                    setSelectedTags(updatedTags);
                } else {
                    setSelectedTags([...selectedTags, { ...tag, values: [value], icon }]);
                }
            }
        } else {
            const existingFilter = selectedTags.find((filter) => filter.name === name);

            if (existingFilter) {
                const updatedTags = selectedTags.map((filter) =>
                    filter.name === name
                        ? { ...filter, values: [...filter.values, value] }
                        : filter
                );

                setSelectedTags(updatedTags);
            } else {
                setSelectedTags([...selectedTags, { ...tag, values: [value] }]);
            }
        }
    };

    const handleAddTagSearch = (tag) => {
        setTagSearch(tag)
        setSearchString('')
    }

    const onKeyDown = (e) => {

        switch (e.key) {
            case 'Backspace':
                if (searchString === '' && selectedTags.length > 0) {

                    setSelectedTags(selectedTags?.slice(0, -1))
                }
                break
            case 'ArrowDown':
                setHoveredIndexSearchList((hoveredIndexSearchList < tableHead.length - 1 ? hoveredIndexSearchList + 1 : hoveredIndexSearchList))
                break
            case 'ArrowUp':
                setHoveredIndexSearchList((hoveredIndexSearchList > 0 ? hoveredIndexSearchList - 1 : hoveredIndexSearchList))
                break
            case 'Enter':
                if (searchString === "") return
                handleAddTagSearch({
                    title: tableHead[hoveredIndexSearchList].title,
                    name: tableHead[hoveredIndexSearchList].name,
                    value: searchString,
                })
                break
            default:
                break
        }
    }

    const handleMouseEnter = (index) => {
        setHoveredIndexSearchList(index)
    }

    const handleMouseLeave = () => {
        setHoveredIndexSearchList(null)
    }

    const onChangeSearchInput = (search_string) => {
        setSearchString(search_string)
        setHoveredIndexSearchList(0)
    }

    const removeSelectedTag = (title) => {
        const newTags = selectedTags.filter((tag) => tag.title !== title)
        setSelectedTags(newTags)
    }

    return {
        handleAddTagSearch,
        handleMouseEnter,
        handleMouseLeave,
        onKeyDown,
        onChangeSearchInput,
        removeSelectedTag
    }
}

export default useSearch
