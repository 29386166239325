import React, { forwardRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import ImageWithBasePath from '../general/ImageCustom'

const DateField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods = null,
  isForm
}) => {

  const InputDateCustom = forwardRef(
    ({ value, onClick, className, onChange, isForm }, ref) => (
      <label className={`flex ${className}`}>
        <input className='bg-transparent w-full' required={required} readOnly={readonly} type='' onClick={onClick} onChange={onChange} ref={ref} value={moment(value).isValid() ? moment(value).format("DD/MM/YYYY") : ""} />
        {isForm && <ImageWithBasePath src={'assets/img/icons/calendar.svg'} />}
      </label>
    ),
  );

  if (!isForm) {
    return <span>{moment(value).isValid() ? moment(value).format("DD/MM/YYYY HH:mm:ss") : ""}</span>
  } else {
    return (
      <Controller
        name={name}
        control={methods?.control}
        defaultValue={moment().subtract(1, 'days').format('YYYY-MM-DD')}
        render={({ field }) => {

          return (
            <DatePicker
              selected={!methods ? value : field.value ? new Date(field.value) : null}
              onChange={(date) => {
                const formattedDate = moment(date).format('YYYY-MM-DD')
                field.onChange(formattedDate)
              }}
              readOnly={isForm ? readonly : true}
              placeholderText={placeholder}
              required={required}
              wrapperClassName={`w-full`}
              customInput={<InputDateCustom isForm={isForm} className={`${invisible ? 'hidden' : ''} ${!isForm ? "!bg-transparent focus-within:!border-none" : "field"}`} />}
              portalId="root-portal"
            />
          )
        }}
      />
    )
  }
}

export default DateField
