import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import useAsync from '../../hooks/useAsync'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONDomain } from '../../util/util'
import { useTranslation } from 'react-i18next'

const Many2OneField = ({
  relation,
  name,
  methods,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  isForm,
  domain,
  defaultValue
}) => {
  const { t } = useTranslation()
  const { data: dataOfSelection } = useAsync(
    () => ViewServices.getSelectionItem({ model: relation, domain: evalJSONDomain(domain, methods ? methods.getValues() : {}) }),
    `data_${relation}`
  )
  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.display_name,
    })) || []

  console.log("🚀 ~ options:", options)

  if (!isForm) {
    const defaultValueOption = options.find((option) => option.value === defaultValue)

    return <span>{defaultValueOption?.label}</span>
  } else {
    return (
      <Controller
        name={name}
        control={methods.control}
        render={({ field }) => {
          return (
            <Select
              {...field}
              options={options}
              classNames={{
                control: () => isForm ? "field" : "field-in-tree",
                valueContainer: () => 'm-0 p-0',
                singleValue: () => 'm-0',
                input: () => 'm-0 p-0',
                dropdownIndicator: () => 'm-0 p-0'
              }}
              placeholder={placeholder || t(`choose_place`)}
              isSearchable={!readonly}
              onChange={(selectedOption) => field.onChange(selectedOption.value)}
              value={options.find((option) => option.value === field.value)}
              required={!invisible && required}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: !isForm && 'unset',
                  borderWidth: !isForm && 0,
                  backgroundColor: !isForm && 'transparent',
                })
              }}
            />
          )
        }}
      />
    )
  }
}

export default Many2OneField
