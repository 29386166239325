import axios from 'axios'
import { baseURL, authTokenPath } from '../../util/constant/endpoint'
import { CookieStorage } from '../../util/lib/Cookies'

// for multiple requests
let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue?.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 50000,
})

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = CookieStorage.getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response) => {
    return handleResponse(response)
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 502) {
      window.location.href = '/undermaintainence'
    }
    console.log('error.response?.status', error)
    if (error.response?.status === 401 || error.response?.status === 403) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return instance.request(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      } else {
        // CookieStorage.clearToken();
        // window.location.href = '/signin';
        // alert('Your login session has expired, please log in again!');
        isRefreshing = true

        const refreshToken = CookieStorage.getRefreshToken()

        return new Promise(function (resolve, reject) {
          axios
            .post(
              `${baseURL + authTokenPath}`,
              new URLSearchParams({
                refresh_token: refreshToken,
                grant_type: 'refresh_token',
                client_id: process.env.REACT_APP_CLIENT_ID,
                client_secret: process.env.REACT_APP_CLIENT_SECRET,
              }).toString()
            )
            .then((res) => {
              const data = res.data

              // // 1) put token to CookieStorage
              CookieStorage.setToken(data.access_token)
              if (data?.refreshToken) {
                CookieStorage.setRefreshToken(data.refresh_token)
              }

              // // 2) Change Authorization header
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken
              originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken
              processQueue(null, data.accessToken)
              // // 3) return originalRequest object with Axios
              resolve(instance.request(originalRequest))
            })
            .catch((err) => {
              if (err.response) {
                const { status } = err.response

                if (status === 404) {
                  clearAuthToken()
                }
              }

              processQueue(err, null)
              reject(err)
            })
            .finally(() => {
              isRefreshing = false
            })
        })
      }
    }
    return Promise.reject(handleError(error))
  }
)

const handleResponse = (res) => {
  if (res && res.data) {
    return res.data
  }

  return res
}

const handleError = (error) => {
  // Check if it's an Axios error and if it's a network error
  if (error.isAxiosError && error.code === 'ECONNABORTED') {
    console.error('Request Timeout Error:', error)
    // Handle timeout error
    // You can return a custom message or perform any action you want
    return 'Request Timeout Error'
  } else if (error.isAxiosError && !error.response) {
    console.error('Network Error:', error)
    // Handle network error
    // You can return a custom message or perform any action you want
    return 'Network Error'
  } else {
    // For other errors, log and return the error
    console.error('Other Error:', error.response)
    return error.response.data
  }
}
const clearAuthToken = () => {
  CookieStorage.clearToken()
}

const responseBody = (response) => response

const requests = {
  get: (url, body, headers) => instance.get(url, body, headers).then(responseBody),

  post: (url, body, headers) => instance.post(url, body, { headers: headers }).then(responseBody),

  put: (url, body, headers) => instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url, body) => instance.delete(url, body).then(responseBody),
}

export default requests
