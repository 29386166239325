import { useEffect } from "react"
import useFormStore from "../../../store/form"
import useTableStore from "../../../store/table"
import { checkDomain } from "../../../core/domain/domain"
import { useSearchParams } from "react-router-dom"

const useTable = ({ fields, data, dataModel, model, context }) => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get("vid")
  const { setFields } = useTableStore()
  const { viewDataStore } = useFormStore()

  const mergeFields = fields?.map((field) => {
    return { ...dataModel?.[field?.name], ...field }
  })

  useEffect(() => {
    if (mergeFields?.length > 0) {
      setFields({ ...fields, [`${vid}_${model}`]: mergeFields })
    }
  }, [viewDataStore])

  const visibleOptionalFields = mergeFields?.filter((item) => item.optional && !(item?.column_invisible ? checkDomain(context, item?.column_invisible) : false)
  )


  const dataSource = data?.map((item) => {
    const transformedItem = { ...item }

    Object.keys(item).forEach((field) => {
      if (item[field] && typeof item[field] === 'object' && item[field].display_name) {
        transformedItem[field] = item[field].display_name
      } else if (Array.isArray(item[field]) && item[field].length > 0) {
        transformedItem[field] = item[field].map((subItem) => subItem.display_name).join(', ')
      }
    })

    return item.display_name ? { ...transformedItem, item: item.display_name } : transformedItem
  })
  console.log("🚀 ~ dataSource ~ dataSource:", dataSource)

  const handleGetColumns = () => {
    let cols = []
    try {
      cols = mergeFields
        ?.filter((item) => (item?.optional === 'show' || !item?.optional) && !(item?.column_invisible ? checkDomain(context, item?.column_invisible) : false))
        ?.map((field) => {
          return {
            key: field?.name,
            title: field.string,
            field: { ...field },
          }
        })

    } catch (error) {
      console.log('fields:', fields)
      console.error('Error in useTable:', error)
    }

    return cols
  }

  const columns = handleGetColumns()

  return {
    dataSource,
    columns,
    visibleOptionalFields
  }
}

export default useTable
