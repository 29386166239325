import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'

const SelectDropdown = ({
  selection,
  name,
  methods,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  isForm,
  defaultValue
}) => {
  const options = selection
    ? selection.map((val) => ({
      value: val[0],
      label: val[1],
    }))
    : []

  if (!isForm) {
    return <span>{defaultValue}</span>
  }
  else {
    return <Controller
      name={name}
      control={methods.control}
      render={({ field }) => {


        const defaultValue = options.find((option) => option.value === value)
        return (
          <Select
            {...field}
            options={options}
            classNames={{
              control: () => isForm ? "field" : "field-in-tree",
              valueContainer: () => 'm-0 p-0',
              singleValue: () => 'm-0',
              input: () => 'm-0 p-0',
              dropdownIndicator: () => 'm-0 p-0'
            }}
            placeholder={placeholder || 'Choose'}
            isSearchable={!readonly}
            // isDisabled={readonly}
            onChange={(selectedOption) => field.onChange(selectedOption.value)}
            value={options.find((option) => option.value === field.value) || defaultValue}
            required={!invisible && required}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: isForm ? 'initial' : 'unset',
                borderWidth: !isForm && 0,
                backgroundColor: isForm ? '#fff' : 'transparent',
              })
            }}
          />
        )
      }}
    />
  }
}

export default SelectDropdown
