import { ArrowLeft } from "react-feather"
import { useTranslation } from "react-i18next"
import ImageWithBasePath from "../../general/ImageCustom"

const RightToolbar = (props) => {
  const { dataToolbar, isInForm, onClick } = props
  const { t } = useTranslation()

  return (
    <div className='flex items-center gap-[8px]'>
      <div className='page-btn'>
        {dataToolbar?.root?.create && (
          <button className="flex items-center button-primary gap-1 text-[14px]" onClick={onClick}>
            {isInForm ? <ArrowLeft className='text[20px]' /> : <ImageWithBasePath src="assets/img/icons/add.svg" />}
            {isInForm ? t('back') : t("add")}
          </button>
        )}
      </div>
    </div>
  )
}

export default RightToolbar
