import React, { useState } from 'react'
import ModalDelete from '../../tree/ModalDelete'
import useToolBar from '../model/toolbar'
import useToolBarStore from '../../../store/toolbar'
import LeftToolbar from './LeftToolbar'
import Search from './search'
import useTableStore from '../../../store/table'
import ActionCheckbox from './ActionCheckbox'
import RightToolbar from './RightToolbar'
import useFormStore from '../../../store/form'

const Breadcrumbs = (props) => {
  const { vid, model, dataToolbar, actionData, viewData, title, subTitle, isInForm } = props
  const { resModel } = useToolBarStore()
  const { viewDataStore } = useFormStore()
  const { isShow, onClick } = useToolBar(vid, model)
  const { selectedRowKeys } = useTableStore()
  const [openModal, setOpenModal] = useState(0)
  const [openImport, setOpenImport] = useState(false)
  const [approve, setApprove] = useState(false)

  return (
    <div className='flex items-center justify-between !pb-6 pt-8'>
      <LeftToolbar
        title={title}
        subTitle={subTitle}
        isShow={isShow}
        onClick={onClick}
        setOpenImport={setOpenImport}
        setOpenModal={setOpenModal}
        dataToolbar={dataToolbar}
        openImport={openImport}
        actions={viewDataStore?.views?.form?.toolbar?.action}
      />

      {!isShow &&
        (selectedRowKeys?.length > 0 && dataToolbar ? (
          <ActionCheckbox
            dataToolbar={dataToolbar}
            openModal={openModal}
            setApprove={setApprove}
            setOpenImport={setOpenImport}
            setOpenModal={setOpenModal}
          />
        ) : (
          <Search actionData={actionData} viewData={viewData} />
        ))
      }

      {!isShow && (
        <>
          <div className='page-btn flex items-center gap-[8px]'>
            {/* {tabelContext?.dataRow?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalItems={tabelContext?.dataRow?.length}
                itemsPerPage={10}
                setPage={tabelContext?.setPage}
              />
            )} */}
            {/* <button className='rounded-[8px] bg-[#c9c6c6] p-[8px]'>
              <ImageWithBasePath src='assets/img/app-menu.svg' alt='menu icon' className='h-4' />
            </button> */}
          </div>
          {approve && selectedRowKeys && (
            <ModalDelete setApprove={setApprove} selectedRowKeys={selectedRowKeys} resModel={resModel} />
          )}
        </>
      )}

      <RightToolbar
        isInForm={isInForm}
        onClick={onClick}
        dataToolbar={dataToolbar}
      />
    </div>
  )
}

export default Breadcrumbs
