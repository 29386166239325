import React from 'react';
import useCheckbox from '../model/checkbox';
import useTableStore from '../../../store/table';
import TableFilter from './table-filter';

const TableHead = ({ columns, dataSource, visibleOptionalFields, model, isForm }) => {
  const { selectedRowKeys, setSelectedRowKeys } = useTableStore();
  const { handleSelectAllChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
  const isAllSelected = selectedRowKeys.length === dataSource?.length;

  return (
    <thead>
      <tr>
        <th className={`${isForm ? 'hidden' : ''} w-12 p-3 border-b-[1.5px] border-[#DEE7DD] text-left font-medium text-gray-500 uppercase`}>
          <input type="checkbox" checked={isAllSelected} onChange={handleSelectAllChange} className='align-middle ml-2' />
        </th>
        {
          columns?.map((col, index) => (
            <th
              key={"table-head-" + index}
              className={`border-b-[1.5px] p-3 whitespace-nowrap border-[#DEE7DD] text-left text-sm font-medium text-[#121212] capitalize column`}
            >{col.title}</th>
          ))}
        {
          visibleOptionalFields?.length > 0 &&
          <th className={`border-b-[1.5px] sticky z-50 px-2 right-0 whitespace-nowrap bg-white border-[#DEE7DD] text-gray-500 column`}>
            <TableFilter model={model} visibleOptionalFields={visibleOptionalFields} />
          </th>
        }
      </tr>
    </thead>
  )
}

export default TableHead
