import React, { useEffect, useId, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import ImageWithBasePath from '../general/ImageCustom'

const BinaryField = ({ name, methods, readonly, savedDataDetail, placeholder, required, invisible }) => {
  const inputId = useId()
  const [selectedImage, setSelectedImage] = useState(null)
  const [initialImage, setInitialImage] = useState(savedDataDetail?.image_1920 || null)

  const handleImageChange = (e, onChange) => {
    const file = e.target.files[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setSelectedImage(imageUrl)
      setInitialImage(null) // Clear initial image when a new image is selected
      onChange(file) // Update the form state with the selected file
    }
  }

  const handleRemoveImage = (onChange) => {
    setSelectedImage(null)
    setInitialImage(null) // Clear initial image when removed
    onChange(null) // Update the form state to remove the file
  }

  useEffect(() => {
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage)
      }
    }
  }, [selectedImage])

  useEffect(() => {
    setSelectedImage(savedDataDetail?.image_1920)
  }, [savedDataDetail])

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => {
        const renderImage = initialImage || selectedImage
        return (
          <div className='relative flex w-fit items-center gap-4 rounded-lg shadow-md mb-6'>
            {renderImage ? (
              <div className='relative'>
                <img
                  src={renderImage}
                  alt='Selected'
                  className='h-32 w-32 rounded-lg border-4 border-gray-300 object-cover'
                />
                <button
                  type='button'
                  onClick={() => handleRemoveImage(field.onChange)}
                  className='absolute right-0 top-0 mr-1 mt-1 rounded-full bg-red-500 p-1 transition-colors duration-300 hover:bg-red-600'
                >
                  <ImageWithBasePath src='assets/img/close-icon.svg' alt='close-icon' className='h-4 w-4' />
                </button>
              </div>
            ) : (
              <label
                htmlFor={inputId}
                className='flex h-32 w-32 cursor-pointer items-center justify-center rounded-lg border-4 border-dashed border-gray-300 bg-white transition-colors duration-300 hover:bg-gray-200'
              >
                <ImageWithBasePath src='assets/img/add-icon.svg' alt='add-icon' className='h-12 w-12 opacity-50' />
                <input
                  id={inputId}
                  type='file'
                  readOnly={readonly}
                  placeholder={placeholder}
                  required={!invisible && required}
                  onChange={(e) => handleImageChange(e, field.onChange)}
                  className='hidden'
                />
              </label>
            )}
          </div>
        )
      }}
    />
  )
}

export default BinaryField
