import React from 'react'
import { useFormContext } from 'react-hook-form'
import useField from './field'
import RenderWidget from '../widget/RenderWidget'
import PropTypes from 'prop-types'

const FieldView = (props) => {
  const { name, string, onchangeData, isForm, defaultValue } = props
  const methods = useFormContext()
  const { invisible, required, readonly, nameField } = useField(props)

  const inputElement = RenderWidget({
    ...props,
    required: required,
    readonly: readonly,
    invisible: invisible,
    name: nameField || name,
    methods: methods,
    value: onchangeData?.[name],
    isForm: isForm,
    defaultValue: defaultValue
  })

  if (isForm && (invisible || inputElement === null)) return null

  return (
    <div className={`${isForm && string ? "min-h-[100px]" : "min-h-max w-max"} col-span-2`}>
      {isForm && string && <div className='flex gap-[1px] h-5 mb-2'>
        <span className='text-sm font-medium text-dark'>{string}</span>
        {required && (
          <span style={{ verticalAlign: 'super' }} className='text-sm text-[#DD441A]'>
            *
          </span>
        )}
      </div>}
      {inputElement}
    </div>
  )
}

FieldView.propTypes = {
  name: PropTypes.string,
  invisible: PropTypes.arrayOf(PropTypes.string),
  string: PropTypes.string,
  value: PropTypes.any,
  widget: PropTypes.string,
  onchangeData: PropTypes.object,
  relation: PropTypes.string,
  index: PropTypes.number,
  rootField: PropTypes.object,
  isForm: PropTypes.bool
}

export default FieldView
