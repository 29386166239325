import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ViewServices from '../../core/services/view-service/view-service'
import { buildUrl } from '../../Router/url'
import { menu_focus } from '../../util/redux/Action'
import ImageWithBasePath from '../general/ImageCustom'
import { useMetaContext } from '../../Router/MetaContext'
import useHeaderStore from '../../store/header'

const Sidebar = () => {
  const [openMenus, setOpenMenus] = useState({})
  const [menuFocus, setMenuFocus] = useState()
  const [toggle, setToggle] = useState(false)
  const { metadata, loadingImport } = useMetaContext()
  const { lang } = useHeaderStore()
  let pathname = location.pathname

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: listMenu } = useQuery({
    queryKey: ['get_menu_view', lang],
    queryFn: () => ViewServices.getMenuView(lang)
  })

  const handleSidebar = (e) => {
    e.preventDefault()
    document.body.classList.toggle('mini-sidebar')
    setToggle((current) => !current)
  }
  const expandMenu = () => {
    document.body.classList.remove('expand-menu')
  }
  const expandMenuOpen = () => {
    document.body.classList.add('expand-menu')
  }


  const toggleMenu = (item, level) => {
    setOpenMenus((prev) => {
      const newOpenMenus = { ...prev }
      Object.keys(newOpenMenus).forEach((key) => {
        if (newOpenMenus[key] && key !== item.id.toString() && key.startsWith(`level-${level}-`)) {
          delete newOpenMenus[key]
        }
      })
      newOpenMenus[`level-${level}-${item.id}`] = !prev[`level-${level}-${item.id}`]
      sessionStorage.setItem("parent_icon", JSON.stringify(item))
      return newOpenMenus
    })
  }

  useEffect(() => {
    const url_icon = JSON.parse(sessionStorage.getItem("url_icon"))
    const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon"))
    if (listMenu && !url_icon && !parent_icon) {
      const parent_icon = listMenu?.records[0]
      const url_icon = parent_icon.child_id[0]
      sessionStorage.setItem("parent_icon", JSON.stringify(parent_icon))
      sessionStorage.setItem("url_icon", JSON.stringify(url_icon))
    }
  }, [listMenu])

  const handleNavigation = (item) => {
    const id = item?.action?.id?.id
    const model = item?.action?.res_model
    const view = item?.action?.binding_view_types?.split(',')[0]

    sessionStorage.setItem("url_icon", JSON.stringify(item))

    dispatch(menu_focus(id))
    navigate(buildUrl(view, id, model))
  }

  const renderMultiLevelMenu = (items, level = 0) => {
    return items.map((item, index) => {
      return (
        <>
          {item.is_display ? (
            <li
              className={`submenu submenu-level-${level} ${!item.child_id || item.child_id.length === 0 ? '' : ''}`}
              key={`${level}-${item.id || index}`}
            >
              <button
                className={`button-menu-item btn-transparent w-full ${(menuFocus == item.id) ? 'menu-focus' : ''}`}
                onClick={() => {
                  if (item.child_id && item.child_id.length > 0) {
                    toggleMenu(item, level)
                  } else {
                    handleNavigation(item)
                    setMenuFocus(item.id)
                  }
                }}
              >
                <Link
                  to='#'
                  onClick={(e) => {
                    e.preventDefault()
                    // Your click handler logic here
                  }}
                  className={openMenus[`level-${level}-${item.id}`] ? 'subdrop' : ''}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                    }}
                  >
                    {/* <img className='icon submenu-hdr'
                      src={item?.url_icon && item?.url_icon?.startsWith("http") ? item.url_icon : process.env.REACT_APP_DOMAIN + item.url_icon}
                      width={16}
                      height={16}
                      alt="img"
                    /> */}
                    <span className="custom-active-span leading-5 text-[rgba(255,255,255,1)]  font-medium !text-[14px]">
                      {item.name}
                    </span>

                  </div>

                  {item.child_id && item.child_id.length > 0 && <span className='menu-arrow' />}
                </Link>
              </button>
              {item.child_id && item.child_id.length > 0 && (
                <ul
                  style={{
                    display: openMenus[`level-${level}-${item.id}`] && item.is_display ? 'block' : 'none',
                    marginLeft: level * 15,
                  }}
                  className={`mega-menu`}
                >
                  {renderMultiLevelMenu(item.child_id, level + 1)}
                </ul>
              )}
            </li >
          ) : (
            <></>
          )
          }
        </>
      )
    })
  }

  return (
    <div>
      <div className='sidebar !top-0 fixed flex flex-col' id='sidebar' onMouseLeave={expandMenu} onMouseOver={expandMenuOpen}>
        {/* Logo */}
        <div className={`header-left !px-4 mt-8 ${toggle ? '' : 'active'}`} >
          <Link
            onClick={() => {
              navigate('/list?vid=511&model=res.partner')
            }}
            className='logo logo-normal '
          >
            {metadata &&
              (!loadingImport ? (
                <div className='flex items-center gap-[16px]'>
                  <div className='flex h-[28px] w-[80px] items-start'>
                    <img src={metadata?.logo} alt='logo' width={140} height={48} className='object-contain object-top max-h-[35px] h-auto' />
                  </div>
                </div>
              ) : (
                <ImageWithBasePath src='../../../public/assets/logo/vitrust-white.svg' alt='img' />
              ))}
          </Link>
          <Link
            onClick={() => navigate('/list?vid=511&model=res.partner')}
            className='logo logo-white'
          >
            <ImageWithBasePath src='assets/logo/vitrust-three.svg' alt='img' />
          </Link>
          <Link
            onClick={() => navigate('/list?vid=511&model=res.partner')}
            className='logo-small'
          >
            <ImageWithBasePath src={metadata?.logo} alt='img' />
          </Link>
          <Link
            id='toggle_btn'
            to='#'
            style={{
              display:
                pathname.includes('tasks') || pathname.includes('pos')
                  ? 'none'
                  : pathname.includes('compose')
                    ? 'none'
                    : '',
            }}
            onClick={handleSidebar}
            className='!size-8 flex items-center justify-center shadow cursor-pointer hover:shadow-2xl '
          >
            <img src={'assets/img/arrow.svg'} alt="arrow" className={`size-6 ${toggle ? 'rotate-180' : ''} transform transition-all`} />
          </Link>
        </div>
        <Scrollbars>
          <div className='sidebar-inner slimscroll flex flex-col h-full'>
            <div id='sidebar-menu' className='sidebar-menu flex-1 '>
              <ul>
                {listMenu?.records?.map((mainLabel, index) => {
                  return (
                    <li className={`submenu-open submenu-level-${-1}`} key={index}
                      style={{
                        display: mainLabel.is_display ? "block" : "none",
                      }}>
                      <button
                        className={'btn-transparent button-menu-item w-full'}
                        onClick={() => {
                          if (mainLabel.child_id && mainLabel.child_id.length > 0) {
                            toggleMenu(mainLabel, -1)
                          } else {
                            handleNavigation(mainLabel)
                          }
                        }}
                      >
                        <Link
                          to='#'
                          onClick={(e) => {
                            e.preventDefault()
                            // Your click handler logic here
                          }}
                          className={openMenus[`level-${-1}-${mainLabel.id}`] ? 'subdrop' : ''}
                        >
                          <div
                            className='menu-item'
                          >
                            <img className='icon submenu-hdr'
                              src={mainLabel?.url_icon && mainLabel?.url_icon?.startsWith("http") ? mainLabel.url_icon : process.env.REACT_APP_DOMAIN + mainLabel.url_icon}
                              width={16}
                              height={16}
                              alt="img"
                            />
                            <h6 className='submenu-hdr font-medium  leading-5 text-[rgba(255,255,255,1)] !text-[14px]  whitespace-nowrap'>{mainLabel?.name}</h6>
                            <span className='menu-arrow' />
                          </div>
                        </Link>
                      </button>

                      {mainLabel.child_id && mainLabel.child_id.length > 0 && (
                        <ul
                          style={{
                            display: openMenus[`level-${-1}-${mainLabel.id}`] && mainLabel.is_display ? 'block' : 'none',
                            marginLeft: 8,
                          }}
                          className={`mega-menu`}
                        >{renderMultiLevelMenu(mainLabel.child_id)}</ul>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
            <div
              data-author='atom'
              className=" px-4 pb-8 flex items-center gap-1 justify-center ">
              <span className="text-[12px] text-white leading-4 ">
                Powered by
              </span>
              <img className='icon submenu-hdr'
                src={'assets/img/atom-sidebar.png'}
                width={51}
                height={20}
                alt="img"
              />
            </div>
          </div>
        </Scrollbars>

      </div>
    </div>
  )
}

export default Sidebar
